<template>

	<div class="wrap scroll">
		<!-- header -->
		<header class="header-step">
			<div class="head-left">
				<button type="button" class="btn-close" @click="winClose"><span class="blind">닫기</span></button>
			</div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit">{{ pageVO.pageName }}</span>
				</div>
			</div>
			<div class="head-right"></div>
		</header>
		<!-- container -->
		<div class="container" >
			<div class="content" v-html="pageVO.contentHtml" style="padding-top: 30px; background: #FFF; padding-left: 30px; padding-right: 30px;">
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "privacypolicy",
	data () {

		return {
			pageCode: this.$route.params.PAGE_CODE,
			pageVO: {},
		}
	},
	created() {
		this.getStaicPage();
	},
	mounted() {
	},
	methods: {

		getStaicPage() {

			this.$eventBus.$emit("startProgress")
			this.axiosCaller.get(this, this.APIs.STATIC_PAGE + "/" + this.pageCode, {}, (res) => {

				this.$eventBus.$emit("doneProgress")
				const result = res.data
				if ( result.status === "ok" ) {
					this.pageVO = result.pageVO
				}

				if ( result.status === "fail" ) {
					this.swalUtils.gritter(result.status, result.msg, "warning", 3000)
				}

			})

		},

		winClose () {
			this.appClose({
				reload: false,
			})
		}

	}
}
</script>

<style scoped>

</style>